var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "d-flex flex-column mb-4",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise categories"))),
          ]),
          _c("v-select", {
            attrs: {
              label: _vm.$t("Choose"),
              outlined: "",
              required: "",
              "single-line": "",
              "small-chips": "",
              loading: _vm.categoryLoading,
              "no-data-text": _vm.$t("No data"),
              items: _vm.categoryList,
              "item-text": "name",
              "item-value": "id",
              rules: [(v) => !!v],
            },
            model: {
              value: _vm.categoryId,
              callback: function ($$v) {
                _vm.categoryId = $$v
              },
              expression: "categoryId",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise type"))),
          ]),
          _c("v-select", {
            attrs: {
              label: _vm.$t("Choose"),
              outlined: "",
              required: "",
              "single-line": "",
              "small-chips": "",
              loading: _vm.typeLoading,
              "return-object": "",
              "no-data-text": _vm.$t("No data"),
              items: _vm.typeList,
              "item-text": "value",
              rules: [(v) => !!v],
            },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise title"))),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              "single-line": "",
              type: "text",
              rules: [(v) => !!v],
              required: "",
              counter: "128",
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise description"))),
          ]),
          _c("v-textarea", {
            attrs: {
              outlined: "",
              type: "text",
              counter: "600",
              rules: _vm.descriptionRules,
              required: "",
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c(
            "div",
            { staticClass: "mt-1 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "col-12",
                  attrs: {
                    color: "primary",
                    large: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }